const vm = new Vue({
    el: '#categories',
    data: {
        activeFilters: [],
        activeRefinements: [],
        ajaxComplete: false,
        category: '',
        currentPage: 1,
        categoryLink: false,
        dataLoaded: false,
        elipsisDots: '...',
        hasFilters: false,
        header: '',
        hide: [],
        imageSize: {
            miniSwatch: 0,
            snippet: 0,
            thumbnail: 0
        },
        initialDescriptionHtml: '',
        isDesktop: false,
        isMobilePage: false,
        isMobileLandscape: false,
        isMobilePortrait: false,
        isPageLoad: true,
        isProdAreaMobileWidth: false,
        isReadMoreBtnDisplaying: false,
        isTabletLandscape: false,
        isTabletPortrait: false,
        itemsPerPage: 24,
        monetateCartArray: [],
        monetateCartItems: [],
        monetateCategory: '',
        monetateCategoryArray: [],
        monetateProductArray: [],
        options: [],
        outOfStockRemovedItem: false,
        pagination: {},
        paginationRange: [],
        prodAreaWidth: 0,
        products: [],
        readLessButtonText: 'Read Less',
        readMoreButtonText: 'Read More',
        refinementLevel: 1,
        refinements: [],
        resizerParam: '?width=',
        sorting: [],
        sortby: 'score',
        swatches: [],
        title: '',
        totalPages: [],
        trackingId: '',
        truncatedDescriptionHtml: '',
        urls: {
            next: '',
            prev: ''
        },
        viewportHeight: 0,
        viewportWidth: 0,
        zones: []
    },
    computed: {
        userAgent() {
            return window.navigator.userAgent;
        },
        isLandingPage() {
            const pathname = window.location.pathname;

            return pathname.includes('/custom/');
        }
    },
    created() {
        // BEGIN Images
        this.setViewportDimensions();
        this.setDeviceFlags();
        this.setImageWidths();
        // END Images

        this.$nextTick(() => {
            const tabletBreakpoint = 1024;
            const { innerWidth } = window;
            const path = window.location.pathname.replace(/\/$/, '');
            let productDescription;

            // set the product description for mobile & tablet
            if (innerWidth <= tabletBreakpoint) {
                productDescription = document.getElementById('product-description');
                if (productDescription !== null) {
                    this.initialDescriptionHtml = productDescription.innerHTML;
                    this.setProductDescriptionHtml();
                }
            }

            // set mobile page flag
            this.setIsMobile();
            // call SearchByCategory API
            this.getData(path);
            this.updateHistory();
        });
    },
    mounted() {
        // BEGIN Event Listeners
        window.addEventListener('DOMContentLoaded', () => {
            this.setImageUrls('.visualnavigationimageblock img', this.imageSize.thumbnail);
        });
        window.addEventListener('resize', () => {
            this.setViewportDimensions();
            this.setDeviceFlags();
            this.setImageWidths();
            this.setImageUrls('.visualnavigationimageblock img', this.imageSize.thumbnail);
        });
        window.addEventListener('orientationchange', () => {
            this.handleOrientationChange();
        });
        // END Event Listeners
    },
    updated() {
        this.$nextTick(() => {
            // Set mobile page flag
            this.setIsMobile();

            // Remove hide class from elements with the cloak class
            document.querySelectorAll('.cloak').forEach(element => {
                element.classList.remove('hide');
            });

            // Iterate over elements and apply appropriate classes based on data-level attribute
            document.querySelectorAll('#refinements > ul > li, .subfilter-category > li').forEach(element => {
                element.classList.remove('indent-zero', 'indent-one', 'indent-two', 'indent-three');
                const dataLevel = element.getAttribute('data-level');

                switch (dataLevel) {
                    case '2':
                        element.classList.add('indent-one');
                        break;
                    case '3':
                        element.classList.add('indent-two');
                        break;
                    case '4':
                        element.classList.add('indent-three');
                        break;
                    default:
                        element.classList.add('indent-zero');
                }
            });
        });
    },
    methods: {
        checkFilterGroup(filterGroup) {
            if (filterGroup === 'categoryfilter' && !this.isLandingPage) {
                return false;
            }

            // determine if the filter group contains any active filters
            let hasActiveFilters = this.activeFilters.some(activeFilter => activeFilter.Id === filterGroup);

            return hasActiveFilters;
        },
        checkThreshold(item, count) {
            const { TruncateThreshold: threshold, IsExpandSelection: expanded } = item;

            for (let i = 0, j = this.refinements.length; i < j; i += 1) {
                if (!expanded) {
                    return false;
                } else if (threshold > count || threshold === 0) {
                    return false;
                }

                return true;
            }
        },
        clearAllFilters() {
            this.isPageLoad = false;

            let queryString = window.location.search.slice(1);
            let queryStringParameters = queryString ? queryString.split('&') : [];
            let nonCategoryRefinementNames = this.refinements
                .map(refinement => refinement.Name)
                .filter(refinementName => refinementName !== 'Category');
            
            // remove filtering parameters
            queryStringParameters = queryStringParameters.filter(parameter => {
                return !nonCategoryRefinementNames
                    .some(refinementName => decodeURIComponent(parameter)
                    .includes(refinementName));
            });

            // create query string
            let nonFilteringQueryString = `?${queryStringParameters.join('&')}`;

            // remove refinements
            this.refinements.length = 0;

            // update url
            this.getActiveFilters(nonFilteringQueryString);
        },
        clearFilterGroup(filterGroup) {
            this.isPageLoad = false;
            let refinementName = '';

            // Deactivate all refinement filters in the filter group
            this.refinements.forEach((refinement) => {
                if (refinement.Id !== filterGroup) return;
                refinementName = refinement.Name;
                refinement.Filters.forEach((filter) => {
                    filter.Active = false;
                });
            });

            // Remove all querystring parameters containing the refinement name as key
            const queryString = window.location.search.slice(1);
            const queryStringParameters = queryString.split('&');
            for (let i = queryStringParameters.length - 1; i >= 0; i--) {
                if (queryStringParameters[i].indexOf(encodeURIComponent(refinementName)) !== -1) {
                    queryStringParameters.splice(i, 1);
                }
            }

            // Construct updated query string and update url
            let queryStringUpdated = `?${queryStringParameters.join('&')}`;
            this.getActiveFilters(queryStringUpdated);
        },
        createGA4CategoryFilterList() {
            if (this.activeRefinements.length) {
                const GA4FilterList = this.activeRefinements?.flatMap(refinement => refinement.Filters?.map(filter => `${refinement.Name}: ${filter.Label}`))?.join(', ');

                return GA4FilterList;
            }
        },
        createMonetateCartArray() {
            this.monetateCartArray = (this.monetateCartItems || []).map(item => ({
                'currency': item.Currency,
                'productId': item.ProductID,
                'quantity': item.Quantity,
                'sku': item.SkuCode,
                'unitPrice': item.UnitPrice
            }));
        },
        createMonetateCategoryArray() {
            this.monetateCategoryArray = this.monetateCategory?.length ? [this.monetateCategory] : [];
        },
        createMonetateProductArray() {
            const productArray = [];

            this.products.forEach(function (product) {
                productArray.push(product.ItemNumber);
            });

            this.monetateProductArray = productArray;
        },
        displayProductDescription() {
            const productDescription = document.getElementById('product-description');
            const readLessMoreButton = document.getElementById('read-less-more-btn');

            if (productDescription && readLessMoreButton) {
                let newHtml;
                if (this.isReadMoreBtnDisplaying) {
                    newHtml = this.initialDescriptionHtml;
                    readLessMoreButton.innerText = this.readLessButtonText;
                    this.isReadMoreBtnDisplaying = false;
                } else {
                    newHtml = `${this.truncatedDescriptionHtml}${this.elipsisDots}`;
                    readLessMoreButton.innerText = this.readMoreButtonText;
                    this.isReadMoreBtnDisplaying = true;
                }
                productDescription.innerHTML = newHtml;
            }
        },
        getActiveFilters(search) {
            let filteringQueryString = '';
            let filtersArray = [];
            const { location: { href } } = window;
            //ma: retaining current non-filter-related query string parameters, as per CMS-2737
            let nonFilteringQueryString = search || window.location.search ? this.removeValidFilters(search || window.location.search) : '';
            let pageNumber = 0;
            let sortBy = null;
            let productId;
            let refinementName;

            // Determine if this is a landing or category page
            if (this.isLandingPage) {
                // add empty category active refinement object and filters array present in the category but not landing page
                this.activeRefinements[0] = {};
                this.activeRefinements[0].Filters = [];
            }

            // Build filtering querystring from active filters
            for (let i = 0, j = this.refinements.length; i < j; i += 1) {

                productId = this.refinements[i].Id;
                refinementName = this.refinements[i].Name;

                for (let f = 0, k = this.refinements[i].Filters.length; f < k; f += 1) {

                    if (refinementName !== 'Category' || this.isLandingPage) {

                        if (this.refinements[i].Filters[f].Active) {

                            const separator = filteringQueryString.length ? '&' : '';

                            if (refinementName === 'Category') {
                                if (this.refinements[i].Filters[f].Level === this.refinementLevel) {
                                    filteringQueryString += `${separator}${this.refinements[i].Name.trim()}=${this.refinements[i].Filters[f].Value.trim()}`;
                                }
                            } else {
                                filteringQueryString += `${separator}${this.refinements[i].Name.trim()}=${this.refinements[i].Filters[f].Label.trim()}`;
                            }

                            filtersArray = this.activeRefinements[0].Filters.push({
                                Id: productId,
                                Label: this.refinements[i].Filters[f].Label,
                                Value: this.refinements[i].Filters[f].Value,
                                Count: this.refinements[i].Filters[f].Count,
                                Active: this.refinements[i].Filters[f].Active,
                                Type: this.refinements[i].Filters[f].Type,
                                Level: this.refinements[i].Filters[f].Level
                            });

                        } else {
                            const labelToRemove = this.refinements[i].Filters[f].Label;
                            filtersArray = this.activeRefinements[0].Filters.filter(filter => filter.Label !== labelToRemove);
                        }
                    }
                }
            }

            // build querystring
            let url = href.split('?')[0];
            if (nonFilteringQueryString.length || filteringQueryString.length) {
                // add query string separator to url
                url += '?';
                // add non-filtering then filtering querystring to url
                if (nonFilteringQueryString.length) {
                    url += nonFilteringQueryString;
                    if (filteringQueryString.length) {
                        url += `&${filteringQueryString}`;
                    }
                } else if (filteringQueryString.length) {
                // or just filtering querystring to url
                    url += `${filteringQueryString}`;
                }
            }

            // get page number parameter and repaginate
            if (this.getPage()) {
                this.updatePagination();
            }

            // get sortby parameter and update
            if (this.getSortBy()) {
                this.updateSortBy();
            }

            // filter unique label values
            filtersArray = this.activeRefinements?.length ? this.activeRefinements[0].Filters.filter((filter, index, self) => self.findIndex(f => f.Label === filter.Label) === index) : [];

            // set active filters
            this.activeFilters = [];

            for (let l = 0, m = filtersArray.length; l < m; l += 1) {

                productId = filtersArray[l].Id ? filtersArray[l].Id : 'categoryfilter';

                if (productId !== 'categoryfilter' || filtersArray[l].Level === this.refinementLevel) {
                    this.activeFilters.push({
                        Id: productId,
                        FiltersObject: ([{
                            Label: filtersArray[l].Label,
                            Value: filtersArray[l].Value,
                            Count: filtersArray[l].Count,
                            Active: filtersArray[l].Active,
                            Type: filtersArray[l].Type,
                            Level: filtersArray[l].Level
                        }])
                    });
                }
            }

            // run view model update when not on page load
            if (!this.isPageLoad) {
                this.updateViewModel();
            }

            // set hasFilters boolean for UI show/hide
            if (this.activeFilters.length) {
                if (this.activeFilters.length === 1 && this.activeFilters[0].Id === 'categoryfilter' && !this.isLandingPage) {
                    this.hasFilters = false;
                } else {
                    this.hasFilters = true;
                }
            } else {
                this.hasFilters = false;
            }

            window.history.replaceState({ path: url }, '', url);
            return encodeURI(url);
        },
        getCategoryLevel(level) {
            if (level > 1) {
                return true;
            }

            return false;
        },
        getData(urlPathname) {
            // cancel function if missing or empty urlPathname argument
            if (!urlPathname) return;

            this.isPageLoad = true;

            // start spinner
            this.ajaxComplete = false;
            // hide pagination
            this.dataLoaded = false;

            const categoryPayload = {
                url: urlPathname,
                ItemsPerPage: window.localStorage.getItem('ItemsPerPage') || '24',
                Brand: window.brandIdentifier
            };
            cei.shared.ajax.jquery.makeRequest({
                method: 'POST',
                url: '/api/Navigation/SearchByCategory',
                data: categoryPayload
            })
            .then(result => {
                // set values returned from API call
                this.setAPIValues(result);

                // show pagination
                this.dataLoaded = true;

                // stop spinner
                this.ajaxComplete = true;

                // get items per page and text size values saved in local storage
                this.getLocalStorageValues();

                // set active filters from querystring name-value pairs
                this.setActiveFilters(this.getQueryString());
                this.getActiveFilters(window.location.search);

                // set document title if it & landing page exist
                if (this.isLandingPage && this.title) {
                    window.document.title = this.title.substring(this.title.indexOf(">") + 1, this.title.lastIndexOf("<"));
                }

                // set SEO Head Link Elements
                this.setPrevNextLinkElements();

                // set Hawksearch Tracking
                this.trackHawksearchSearch();

                // GA4 - View Item List Event
                this.pushGA4ViewItemList();

                // GA4 - View Search Results Event
                this.pushGA4ViewSearch();

                // make monetateQ call
                this.trackCategoryMonetate();
            })
            .catch(jqXHR => {
                // show pagination
                this.dataLoaded = true;

                // stop spinner
                this.ajaxComplete = true;

                // print error to console
                console.error(`${jqXHR.status} ${jqXHR.statusText}`);
            });
        },
        getLocalStorageValues() {
            const localStorageValues = {
                itemsPerPage: window.localStorage.getItem('ItemsPerPage'),
                textSize: window.localStorage.getItem('TextSize')
            };

            if (!localStorageValues.itemsPerPage || localStorageValues.itemsPerPage === '0') {
                window.localStorage.setItem('ItemsPerPage', this.itemsPerPage || this.pagination.ItemsPerPage);
            }

            if (!localStorageValues.textSize || localStorageValues.textSize === '0') {
                window.localStorage.setItem('TextSize', 'small');
            }

            this.itemsPerPage = parseInt(window.localStorage.getItem('ItemsPerPage'), 10);
            const newTextSize = window.localStorage.getItem('TextSize');
            this.setTextSize(newTextSize);
            this.setTextSizeSelection(newTextSize);
        },
        getPage() {
            const search = window.location.search;
            let qs = search ? search.substring(1) : '';

            if (qs.length) {
                qs = JSON.parse('{"' + qs.replace(/&/g, '","').replace(/\=/g, '":"') + '"}',
                    function (key, value) {
                        return key === '' ? value : decodeURIComponent(value);
                    });
                if (Object.hasOwn(qs, 'Page')) {
                    this.currentPage = Number.parseInt(qs.Page, 10);
                    let propertyCount = Object.keys(qs).length;
                    let separator = propertyCount > 1 ? '&' : '?';

                    return `${separator}Page=${qs.Page}`;
                }
            }

            return false;
        },
        getParameterByName(name, url) {
            let newUrl;
            const newName = name.replace(/[\[\]]/g, '\\$&');
            const regex = new RegExp('[?&]' + newName + '(=([^&#]*)|&|#|$)');

            if (!url) {
                newUrl = window.location.href;
            }
            const results = regex.exec(newUrl);

            if (!results) {
                return null;
            }

            if (!results[2]) {
                return '';
            }

            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        },
        getQueryString() {
            const querystring = [];
            const search = window.location.search;
            const hashes = search ? search.slice(search.indexOf('?') + 1).split('&') : [];

            for (let i = 0, j = hashes.length; i < j; i += 1) {
                let hash = hashes[i].split('=');
                if (typeof querystring[hash[0]] === 'undefined') {
                    querystring.push(hash[0]);
                    querystring[hash[0]] = [hash[1]];
                } else {
                    querystring[hash[0]].push(hash[1]);
                }
            }

            return querystring;
        },
        getRating(rating) {
            const baseRating = Math.floor(rating);
            const floatVal = rating - Math.floor(rating);
            let stars = '';
            let placeholder = 0;
            const maxStars = 5;

            for (let i = 0; i < baseRating; i += 1) {
                placeholder += 1;
                stars += '<i class="fa fa-star"></li>';
            }
            if (floatVal > 0.0000001) {
                placeholder += 1;
                stars += '<i class="fa fa-star-half-alt"></li>';
            }
            for (let j = placeholder; j < maxStars; j += 1) {
                stars += '<i class="far fa-star"></li>';
            }

            return stars;
        },
        getShowMoreCount(item) {
            const { FilterCount: count, TruncateThreshold: threshold } = item;

            if (count > threshold) {
                return count - threshold;
            }

            return '';
        },
        getSortBy() {
            const search = window.location.search;
            let qs = search ? search.substring(1) : '';

            if (qs.length) {
                qs = JSON.parse('{"' + qs.replace(/&/g, '","').replace(/\=/g, '":"') + '"}',
                    function (key, value) {
                        return key === '' ? value : decodeURIComponent(value);
                    });
                if (Object.hasOwn(qs, 'SortBy')) {
                    let sortBy = this.sorting.find(i => qs.SortBy === i.Value.trim());
                    let propertyCount = Object.keys(qs).length;
                    let separator = propertyCount > 1 ? '&' : '?';

                    if (sortBy) {
                        sortBy.Selected = true;
                        this.sortby = qs.SortBy;
                    }

                    return `${separator}SortBy=${qs.SortBy}`;
                }
            }

            return false;
        },
        getSortOrder() {
            for (let i = 0, j = this.sorting.length; i < j; i += 1) {
                if (this.sorting[i].IsDefault) {
                    this.sortby = this.sorting[i].Value.trim();
                    this.sorting[i].Selected = true;
                }
            }
        },
        handleOrientationChange() {
            let gridId = document.getElementById("products-grid");
            this.prodAreaWidth = gridId ? gridId.offsetWidth : 0;
        },
        pageChanged(val) {
            this.isPageLoad = false;

            if (val === 'next') {
                this.currentPage += 1;
            } else if (val === 'prev') {
                this.currentPage -= 1;
            } else {
                this.currentPage = val;
            }
            
            this.updatePagination();
            this.updateViewModel();
            this.scrollToTop();
        },
        popState() {
            this.isPageLoad = false;
            this.getActiveFilters();
            const qs = this.getQueryString();

            if (qs?.Page?.length) {
                this.currentPage = parseInt(qs.Page, 10);
            } else {
                this.currentPage = 1;
            }

            this.pageChanged(this.currentPage);
            this.updateViewModel();
            this.updatePagination();
            this.scrollToTop();
        },
        pushGA4ViewItemList() {
            let GA4Items = cei.shared.tagmanager.GA4BuildProductArray(
                this.products,
                this.category,
                'Category'
            );
            cei.shared.tagmanager.addToGA4DataLayer({
                'event': 'view_item_list',
                'ecommerce': {
                    'content_group': undefined,
                    'items': GA4Items && GA4Items.length ? GA4Items : undefined
                }
            }, true);
        },
        pushGA4ViewSearch() {
            let searchTerm = window.localStorage.getItem('SearchTerm');
            let searchType = window.localStorage.getItem('SearchType');
            if (searchType && searchType === "category") {
                let ga4CategoryFilterList = this.createGA4CategoryFilterList();
                cei.shared.tagmanager.addToGA4DataLayer({
                    event: "view_search_results",
                    search_term: searchTerm ? searchTerm : undefined,
                    search_type: searchType ? searchType : undefined,
                    search_filter: ga4CategoryFilterList ? ga4CategoryFilterList : undefined,
                    num_search_results: this.itemsPerPage ? this.itemsPerPage : undefined,
                    search_result_page_type: "Category"
                });
                window.localStorage.removeItem('SearchTerm');
                window.localStorage.removeItem('SearchType');
            }
        },
        removeURLParameter(url, parameter) {
            let i, prefix, pars;
            const urlparts = url.split('?');

            if (urlparts.length >= 2) {
                prefix = `${encodeURIComponent(parameter)}=`;
                pars = urlparts[1].split(/[&;]/g);
                for (i = pars.length; i-- > 0;) {
                    if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                        pars.splice(i, 1);
                    }
                }

                return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
            }

            return url;
        },
        removeValidFilters(queryString) {
            if (!queryString.length) {
                return queryString;
            }
            
            const nonCategoryRefinementNames = this.refinements
                .map(refinement => refinement.Name)
                .filter(refinementName => refinementName !== 'Category');

            const allQueryStringParameters = queryString.replace('?', '').split('&');

            //ma: CMS-2737: remove filtering querystring parameters
            const nonFilteringQueryStringParameters = allQueryStringParameters.filter(parameter => {
                const decodedParam = decodeURIComponent(parameter);
                return !nonCategoryRefinementNames.some(refinementName => decodedParam.indexOf(refinementName) !== -1);
            });

            return nonFilteringQueryStringParameters?.length ? nonFilteringQueryStringParameters.join('&') : '';
        },
        scrollToTop() {
            // Select either the html or body elements (whichever the browser uses)
            const scrollingElement = document.documentElement || document.body;

            // Scroll to the top of the page with smooth animation
            scrollingElement.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
        },
        setActiveFilters(filters) {
            // cycle through filters (i.e., querystring keys) array
            for (let i = 0, j = filters.length; i < j; i += 1) {
                const key = filters[i];
                const values = filters[key];

                // continue to next key if it is not a non-category refinement
                const nonCategoryRefinementNames = this.refinements
                    .map(refinement => refinement.Name)
                    .filter(refinementName => refinementName !== 'Category');
                if (!nonCategoryRefinementNames.includes(key)) continue;

                // cycle through array of values that correspond to a key
                for (let f = 0, g = values.length; f < g; f += 1) {

                    // cycle through refinements
                    for (let r = 0, s = this.refinements.length; r < s; r += 1) {

                        // cycle through filters within each refinement
                        for (let x = 0, y = this.refinements[r].Filters.length; x < y; x += 1) {
                            const refinementName = this.refinements[r].Name.trim();
                            let refinementFilterValue;

                            // use filter value for landing page category filters and filter label for all other filters 
                            if (refinementName === 'Category' && this.isLandingPage) {
                                refinementFilterValue = this.refinements[r].Filters[x].Value.trim().replace('%7C', '|');
                            } else {
                                refinementFilterValue = this.refinements[r].Filters[x].Label.trim();
                            }

                            // comparison variables
                            let keyLC = filters[i].toLowerCase().trim();
                            let refinementNameLC = refinementName.toLowerCase().trim();
                            let valueLC = values[f].toLowerCase().trim();
                            let refinementFilterValueLC = refinementFilterValue.toLowerCase().trim();

                            // keys must match refinement names and values must match refinement filter values or labels to set refinement filter to active
                            if ((encodeURIComponent(keyLC) === encodeURIComponent(refinementNameLC) ||
                                decodeURIComponent(keyLC) === encodeURIComponent(refinementNameLC) ||
                                keyLC === encodeURIComponent(refinementNameLC)) &&
                                (encodeURIComponent(valueLC) === refinementFilterValueLC ||
                                    decodeURIComponent(valueLC) === refinementFilterValueLC ||
                                    valueLC === refinementFilterValueLC)) {

                                // set refinement filter to active
                                this.refinements[r].Filters[x].Active = true;
                            }
                        }
                    }
                }
            }
        },
        setAPIValues(result) {
            this.activeRefinements = result?.Refinements?.ActiveFilters;
            this.category = result?.PageInformation?.Name;
            this.header = result?.PageInformation?.Header;
            this.options = result?.Pagination?.Options;
            this.pagination = result?.Pagination;
            this.products = result?.Products;
            this.refinements = result?.Refinements?.Filters;
            this.sorting = result?.Sorting?.Options;
            this.title = result?.PageInformation?.Title;
            this.trackingId = result?.TrackingId;
            this.zones = result?.Merchandising?.Items;
        },
        setDeviceFlags() {
            this.isDesktop = false;
            this.isMobileLandscape = false;
            this.isMobilePortrait = false;
            this.isTabletLandscape = false;
            this.isTabletPortrait = false;
            const mobileRegex = /Android|webOS|iP(hone|od)|BlackBerry|IEMobile|Opera Mini/i;
            const tabletRegex = /(Android(?!.*mobile))|iPad|Kindle|Playbook|Silk|(Puffin(?!.(IP|AP|WP)))|Tablet|(Windows(?!.*phone)(?!.*NT)(.touch))/i;

            if (mobileRegex.test(this.userAgent)) {
                if (this.viewportWidth < this.viewportHeight) {
                    this.isMobilePortrait = true;
                } else {
                    this.isMobileLandscape = true;
                }
            } else if (tabletRegex.test(this.userAgent)) {
                if (this.viewportWidth > this.viewportHeight) {
                    this.isTabletLandscape = true;
                } else {
                    this.isTabletPortrait = true;
                }
            } else {
                this.isDesktop = true;
            }
        },
        setImageUrls(className, imageWidth) {
            if (className && imageWidth) {
                let images = this.$el.querySelectorAll(className);

                if (images.length) {
                    images.forEach((image) => {
                        let currentSrc;
                        let newSrc;

                        if (image.hasAttribute('src')) {
                            currentSrc = image.getAttribute('src');
                        } else if (image.hasAttribute('data-src')) {
                            currentSrc = image.getAttribute('data-src');
                        }

                        if (currentSrc?.includes('?')) {
                            newSrc = currentSrc.replace(/\?width=[\d]+/, `${this.resizerParam}${imageWidth}`);
                        } else {
                            newSrc = `${currentSrc}${this.resizerParam}${imageWidth}`;
                        }

                        if (image.hasAttribute('src'))
                            image.setAttribute('src', newSrc);

                        if (image.hasAttribute('data-src'))
                            image.setAttribute('data-src', newSrc);
                    });
                }
            }
        },
        setImageWidths() {
            if (this.isMobilePortrait) {
                this.imageSize.miniSwatch = 0;
                this.imageSize.snippet = 182;
                this.imageSize.thumbnail = 162;
            } else if (this.isDesktop) {
                this.imageSize.miniSwatch = 35;
                this.imageSize.snippet = 269;
                this.imageSize.thumbnail = 182;
            } else if (this.isTabletPortrait || this.isMobileLandscape) {
                this.imageSize.miniSwatch = 0;
                this.imageSize.snippet = 225;
                this.imageSize.thumbnail = 205;
            } else if (this.isTabletLandscape) {
                this.imageSize.miniSwatch = 0;
                this.imageSize.snippet = 219;
                this.imageSize.thumbnail = 144;
            }
        },
        setIsMobile() {
            const mobileBreakpoint = 768;
            const mobileProdArea = 992;

            let gridId = document.getElementById("products-grid");
            this.prodAreaWidth = gridId ? gridId.offsetWidth : 0;
            if (this.prodAreaWidth < mobileBreakpoint) {
                this.isMobilePage = true;
            } else {
                this.isMobilePage = false;
            }
            if (window.innerWidth < mobileProdArea) {
                this.isProdAreaMobileWidth = true;
            } else {
                this.isProdAreaMobileWidth = false;
            }
        },
        setMobileFilters() {
            this.mobileFilters = !this.mobileFilters;
            if (!this.mobileSort) {
                this.mobileSort = !this.mobileSort;
            }
        },
        setMobileSort() {
            this.mobileSort = !this.mobileSort;
            if (!this.mobileFilters) {
                this.mobileFilters = !this.mobileFilters;
            }
        },
        setPrevNextLinkElements() {
            const location = window.location.href;
            let mainUrl = '';
            let newLocation = '';
            let newQueryString = '';
            let nextHref = '';
            let nextLink = '';
            const nextPage = this.currentPage + 1;
            let pageParameter = 'Page=';
            const paramSeparator = '&';
            let prevHref = '';
            let prevLink = '';
            const prevPage = this.currentPage - 1;
            let qsArray = [];
            const queryString = window.location.search ? window.location.search.substring(1) : '';
            const urlSeparator = '?';

            // Create query string
            if (queryString.indexOf(paramSeparator) !== -1) {
                qsArray = queryString.split(paramSeparator);
                const { length: qsArrayLength } = qsArray;
                // Remove page parameter
                for (let i = 0; i < qsArrayLength; i += 1) {
                    if (qsArray[i].indexOf(pageParameter) > -1) {
                        qsArray.splice(i, 1);
                        break;
                    }
                }
                newQueryString = qsArray.join(paramSeparator);
                pageParameter = paramSeparator + pageParameter;
            } else {
                if (queryString.indexOf(pageParameter) === -1) {
                    newQueryString = queryString;
                    if (queryString.length) {
                        pageParameter = paramSeparator + pageParameter;
                    }
                }
            }

            // Create location
            if (window.location.href.indexOf(urlSeparator) > -1) {
                newLocation = window.location.href.slice(0, (window.location.href.indexOf(urlSeparator) + 1));
            } else {
                newLocation = location + urlSeparator;
            }

            // Assemble urls
            mainUrl = newLocation + newQueryString + pageParameter;

            /*
            if CurrentPage is 1, then there is no 'prev' url and the 'next' url has 1 added to the Page value
            else if CurrentPage is > 1 and < TotalPages, then the 'prev' url is one less in Page value and the 'next' is one more in Page Value
            else if CurrentPage is TotalPages, then the 'prev' url is one less in Page value and there is no 'next' url
            */
            if (this.currentPage === 1) {
                prevHref = '';
                nextHref = mainUrl + nextPage;
                prevLink = $('link[rel="prev"]');
                if (prevLink !== null) {
                    prevLink.remove();
                }
            } else if (this.currentPage > 1 && this.currentPage < this.pagination.TotalPages) {
                prevHref = mainUrl + prevPage;
                nextHref = mainUrl + nextPage;
            } else if (this.currentPage === this.pagination.TotalPages) {
                prevHref = mainUrl + prevPage;
                nextHref = '';
                nextLink = $('link[rel="next"]');
                if (nextLink !== null) {
                    nextLink.remove();
                }
            }

            this.urls.prev = prevHref;
            this.urls.next = nextHref;

            // Create Link Elements
            if (this.urls.next !== '') {
                cei.shared.head.setLinkElement('next', this.urls.next);
            }

            if (this.urls.prev !== '') {
                cei.shared.head.setLinkElement('prev', this.urls.prev);
            }
        },
        setProductDescriptionHtml() {
            const charLimit = 414;
            const productDescription = document.getElementById('product-description');
            const readLessMoreButton = document.getElementById('read-less-more-btn');

            if (this.initialDescriptionHtml.length > charLimit) {
                this.truncatedDescriptionHtml = this.initialDescriptionHtml.substr(0, charLimit);
                if (productDescription !== null) {
                    productDescription.innerHTML = this.truncatedDescriptionHtml + this.elipsisDots;
                }
                if (readLessMoreButton !== null) {
                    readLessMoreButton.innerText = this.readMoreButtonText;
                    readLessMoreButton.className = readLessMoreButton.className.replace(/\bhidden\b/g, '');
                }
                this.isReadMoreBtnDisplaying = true;
            } else {
                this.isReadMoreBtnDisplaying = false;
            }
        },
        setRefinement(filter, category, level) {
            this.isPageLoad = false;
            this.currentPage = 1;
            this.updatePagination();

            const trimmedCategory = category.trim();

            if (typeof level !== 'undefined') {
                this.refinementLevel = level;
            }

            let url = window.location.href;
            const categoryToRemove = `Category=${encodeURI(this.category)}&`;

            // Category filters behave as hyperlinks and other filters refine the category result set
            if (trimmedCategory === 'Category' && !this.isLandingPage) {
                const newFilter = filter.split('|')[1].trim();
                if (url.indexOf('?') !== -1) {
                    url = url.replace(categoryToRemove, '');
                    url = url.split('?')[1].trim();
                    url = window.location.href = '/' + newFilter + '?' + url;
                    url = this.removeURLParameter(url, 'Page');
                } else {
                    url = '/' + filter;
                    window.location.href = '/' + newFilter;
                }
            } else {
                this.getActiveFilters();
            }
        },
        setSortOrder(e) {
            this.isPageLoad = false;
            this.sortby = e.target.value;
            this.currentPage = 1;
            this.updatePagination();
            this.updateSortBy();
            this.updateViewModel();
            this.toggleMobileOptions();
        },
        setTextSize(size) {
            const categories = document.getElementById('categories');
            categories.classList.remove('text-small', 'text-medium', 'text-large');
            categories.classList.add(`text-${size}`);
        },
        setTextSizeSelection(size) {
            const textSizeLinks = document.querySelectorAll('#text-size a');

            textSizeLinks.forEach(link => {
                link.classList.remove('btn-primary', 'btn-default');

                if (link.dataset.textsize === size) {
                    link.classList.add('btn-primary');
                    this.setTextSize(size);
                    window.localStorage.setItem('TextSize', size);
                } else {
                    link.classList.add('btn-default');
                }
            });
        },
        setViewportDimensions() {
            this.viewportHeight = window.innerHeight;
            this.viewportWidth = window.innerWidth;
        },
        toggleCategories(val, event) {
            const category = document.querySelectorAll(`.subfilter-${val}`);
            const elem = event.target;

            category.forEach(element => {
                if (window.getComputedStyle(element).display === 'none' || window.getComputedStyle(element).display === '') {
                    element.style.display = 'block';
                } else {
                    element.style.display = 'none';
                }
            });

            const icon = elem.closest('.row').querySelector('i');
            if (icon.classList.contains('fa-plus')) {
                icon.classList.remove('fa-plus');
                icon.classList.add('fa-minus');
            } else {
                icon.classList.remove('fa-minus');
                icon.classList.add('fa-plus');
            }
        },
        toggleMobileOptions(target) {
            const mobileFilters = document.getElementById('mobile-filters');
            const mobileSort = document.getElementById('mobile-sort');

            switch (target) {
                case 'filters':
                    if (mobileFilters && mobileSort) {
                        if (mobileFilters.style.display = '' || mobileFilters.style.display === 'none') {
                            mobileFilters.style.display = 'block';
                            mobileSort.style.display = 'none';
                        } else {
                            mobileFilters.style.display = 'none';
                        }
                    }
                    break;
                case 'sort':
                    if (mobileFilters && mobileSort) {
                        if (mobileSort.style.display = '' || mobileSort.style.display === 'none') {
                            mobileSort.style.display = 'block';
                            mobileFilters.style.display = 'none';
                        } else {
                            mobileSort.style.display = 'none';
                        }
                    }
                    break;
                default:
                    if (mobileFilters && mobileSort) {
                        mobileSort.style.display = 'none';
                        mobileFilters.style.display = 'none';
                    }
            }
        },
        toggleShowMore(item) {
            if (this.refinements[item].IsExpandSelection) {
                this.refinements[item].IsExpandSelection = false;
            } else {
                this.refinements[item].IsExpandSelection = true;
            }
        },
        trackCategoryMonetate() {
            window.monetateQ = window.monetateQ || [];
            const monetate = cei.shared.monetate;
            
            monetate.setPageType('Category');
            
            if (this.monetateCategory?.length) {
                this.createMonetateCategoryArray();
                monetate.addCategories(this.monetateCategoryArray);
            }
            if (this.products?.length) {
                this.createMonetateProductArray();
                monetate.addProducts(this.monetateProductArray);
            }
            if (this.monetateCartItems?.length) {
                this.createMonetateCartArray();
                monetate.addCartRows(this.monetateCartArray);
            }

            monetate.trackData();
        },
        trackGTMClick(index, product) {
            let searchType = window.localStorage.getItem('SearchType');
            let searchTerm = window.localStorage.getItem('SearchTerm');

            if (product) {
                // Price
                let regularPrice = product.Price ? product.Price : undefined;
                let salePrice = product.SalePrice ? product.SalePrice : undefined;
                let priceObj = cei.shared.tagmanager.GA4SetPriceObject(regularPrice, salePrice, true);
                
                // GTM
                // HawkSearch
                cei.shared.tagmanager.addToDataLayer({
                    'event': 'hawksearchClick',
                    'trackingId': this.trackingId,
                    'index': index,
                    'uniqueId': product.ItemNumber ? product.ItemNumber : ''
                });
                // GA4
                cei.shared.tagmanager.addToGA4DataLayer({
                    'event': 'select_item',
                    'ecommerce': {
                        'content_group': undefined,
                        'items': [
                            {
                                'item_id': product.ItemNumber ? product.ItemNumber : undefined,
                                'item_name': product.H1Keyword ? product.H1Keyword : undefined,
                                'item_brand': product.Brands ? product.Brands : undefined,
                                'item_category': undefined,
                                'item_category2': undefined,
                                'item_category3': undefined,
                                'item_category4': undefined,
                                'item_category5': undefined,
                                'category': product.Category ? product.Category : undefined,
                                'subcategory': product.SubCategory ? product.SubCategory : undefined,
                                'season': product.Season ? product.Season : undefined,
                                'item_variant': product.ItemVariant ? product.ItemVariant : undefined,
                                'affiliation': 'online store',
                                'price': priceObj.price ? priceObj.price : undefined,
                                'discount': priceObj.priceDifference ? cei.shared.utilities.round(priceObj.priceDifference, 2) : undefined,
                                'price_type': product.PriceType ? product.PriceType : undefined,
                                'quantity': 1,
                                'item_list_name': this.category,
                                'item_list_location': 'Category',
                                'item_list_id': undefined,
                                'index': index + 1,
                                'inventory': product.inventory ? product.inventory : undefined,
                                'rating': product.Rating ? product.Rating : undefined,
                                'drop_ship': product.Drop_Ship ? product.Drop_Ship : undefined,
                                'new': product.New ? product.New : undefined,
                                'exclusive': product.Exclusive ? product.Exclusive : undefined,
                                'personalized': product.Personalized ? product.Personalized : undefined
                            }
                        ]
                    }
                }, true);
            }
            if (searchType && searchType === 'category') {
                cei.shared.tagmanager.addToGA4DataLayer({
                    'event': 'search_result_selected',
                    'search_term': searchTerm ? searchTerm : undefined,
                    'search_type': searchType ? searchType : undefined,
                    'search_result': this.category
                });
            }
        },
        trackHawksearchSearch() {
            const hawksearchCategory = {
                'event': 'Category',
                'category': this.category
            };
            const hawksearchSearchTracking = {
                'event': 'searchtracking',
                'trackingId': this.trackingId
            };

            for (const el of [hawksearchCategory, hawksearchSearchTracking]) {
                cei.shared.tagmanager.addToDataLayer(el);
            }
        },
        updateHistory() {
            window.addEventListener('popstate', () => {
                this.popState();
            });
        },
        updateItemsPerPage(val) {
            this.isPageLoad = false;
            this.itemsPerPage = val;
            window.localStorage.setItem('ItemsPerPage', val);

            const itemsPerPageButtons = document.querySelectorAll('.items-per-page');
            itemsPerPageButtons.forEach(button => {
                const isSelected = parseInt(button.textContent) === val;
                button.classList.toggle('btn-primary', isSelected);
                button.classList.toggle('btn-default', !isSelected);
            });

            this.currentPage = 1;
            this.updatePagination();
            this.updateViewModel();
        },
        updatePagination() {
            let qs = window.location.search.substring(1);
            let { location: { href: url } } = window;

            if (this.currentPage > 1) {
                [url] = url.split('?');
                if (qs.length) {
                    qs = JSON.parse('{"' + qs.replace(/&/g, '","').replace(/[=]/g, '":"') + '"}',
                        function (key, value) {
                            return key === '' ? value : decodeURIComponent(value);
                        });
                    if (Object.hasOwn(qs, 'Page')) {
                        qs = window.location.search;
                        qs = qs.replace(/(Page=)[^\&]+/, 'Page=' + this.currentPage);
                        url += qs;
                    } else {
                        qs = window.location.search;
                        url = url + qs + '&Page=' + this.currentPage;
                    }
                } else {
                    qs = window.location.search;
                    url = url + qs + '?Page=' + this.currentPage;
                }
                window.history.replaceState({ path: url }, '', url);
            } else {
                if (qs.length) {
                    qs = JSON.parse('{"' + qs.replace(/&/g, '","').replace(/[=]/g, '":"') + '"}',
                        function (key, value) {
                            return key === '' ? value : decodeURIComponent(value);
                        });
                    if (Object.hasOwn(qs, 'Page')) {
                        url = this.removeURLParameter(window.location.href, 'Page');
                        window.history.replaceState({ path: url }, '', url);
                    }
                }
            }
        },
        updateSortBy() {
            let qs = window.location.search.substring(1);
            let { location: { href: url } } = window;

            if (this.sortby) {
                [url] = url.split('?');
                if (qs.length) {
                    qs = JSON.parse('{"' + qs.replace(/&/g, '","').replace(/[=]/g, '":"') + '"}',
                        function (key, value) {
                            return key === '' ? value : decodeURIComponent(value);
                        });
                    if (Object.hasOwn(qs, 'SortBy')) {
                        qs = window.location.search;
                        qs = qs.replace(/(SortBy=)[^\&]+/, 'SortBy=' + this.sortby);
                        url += qs;
                    } else {
                        qs = window.location.search;
                        url = url + qs + '&SortBy=' + this.sortby;
                    }
                } else {
                    qs = window.location.search;
                    url = url + qs + '?SortBy=' + this.sortby;
                }
                window.history.replaceState({ path: url }, '', url);
            } else {
                if (qs.length) {
                    qs = JSON.parse('{"' + qs.replace(/&/g, '","').replace(/[=]/g, '":"') + '"}',
                        function (key, value) {
                            return key === '' ? value : decodeURIComponent(value);
                        });
                    if (Object.hasOwn(qs, 'SortBy')) {
                        url = this.removeURLParameter(window.location.href, 'SortBy');
                        window.history.replaceState({ path: url }, '', url);
                    }
                }
            }
        },
        updateViewModel() {
            // start spinner
            this.ajaxComplete = false;

            // create category payload
            const urlPathname = window.location.pathname.replace(/\/$/, '');
            const categoryPayload = {
                url: urlPathname,
                ItemsPerPage: this.itemsPerPage,
                PageNumber: this.currentPage,
                CurrentSort: this.sortby,
                ActiveFilters: this.activeFilters,
                Brand: window.brandIdentifier
            };

            cei.shared.ajax.jquery.makeRequest({
                method: 'POST',
                url: '/api/Navigation/SearchByCategory',
                data: categoryPayload
            })
            .then(result => {
                // set values returned from API call
                this.setAPIValues(result);

                // stop spinner
                this.ajaxComplete = true;

                // get items per page and text size values saved in local storage
                this.getLocalStorageValues();

                // set active filters from querystring name-value pairs
                this.setActiveFilters(this.getQueryString());

                // SEO Head Link Elements
                this.setPrevNextLinkElements();

                // GA4 - View Search Results Event
                this.pushGA4ViewSearch();

                // monetateQ call
                this.trackCategoryMonetate();
            })
            .catch(jqXHR => {
                // stop spinner
                this.ajaxComplete = true;

                // print error to console
                console.error(`${jqXHR.status} ${jqXHR.statusText}`);
            });
        }
    }
});
